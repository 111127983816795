// General Page Section
// General Page Section
export const HOME_PAGE = '/';

// Verification Process Pages
export const SUCCESS_REGISTER = "/success-registered";
export const VERIFY_PAGE = '/verify';
export const EMAIL_VERIFY_PAGE = '/email-verify';
export const VERIFIED_PAGE = '/verified';
export const UPLOAD_PAGE = '/upload';
export const VERIFICATION_PAGE = '/verification';
export const APPROVE_PAGE = '/approved';

// Profile Page Section
export const DOCTOR_PROFILE_PAGE = '/profile';
export const DOCTOR_EDIT_PROFILE_PAGE = '/edit-profile';
export const DOCTOR_DASHBOARD_PAGE = '/dashboard';
export const DOCTOR_PREVIEW = '/dentist/';

export const DOCTOR_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = '/edit';
export const AGENT_IMAGE_EDIT_PAGE = '/change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = '/change-password';
export const CHANGE_USER_PASSWORD_PAGE = '/update-password';
export const AGENT_PROFILE_DELETE = '/delete';
export const AGENT_PROFILE_FAVOURITE = '/favourite-post';
export const AGENT_PROFILE_CONTACT = '/contact';

export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_PAGE = '/privacy';
export const ADD_HOTEL_PAGE = '/add-hotel';

export const ADD_REVIEW_PAGE = '/add-review';
export const ADD_DENTIST_REVIEW_PAGE = '/add-dentist-review';

// Login / Registration Page
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';

export const HOME_PAGE_STUDENTS = '/students';
export const HOME_PAGE_PATIENTS = '/patients';
export const AGENTS_PAGE = '/agents';
export const SIGNUP_PAGE = '/signup';
export const SIGNIN_PAGE = '/signin';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/articles';
export const SINGLE_POST_PAGE = '/article';

// Procedure Page
export const PROCEDURE_PAGE = '/procedures';
export const PROCEDURE_DETAIL_PAGE = '/procedure-details';
export const PROCEDURE_DETAIL_PAGE_FORWARDSLASH = '/procedure-details/';
export const PROCEDURE_LIST_PAGE = '/procedure-list';

// Add New Qustion
export const ADD_QUESTION_PAGE = '/ask-question';
export const ALL_QUESTION_PAGE = '/quest-replies';
export const VIEW_ALL_QUESTION_PAGE = '/view-all-questions';
export const QUESTIONS_DETAILS = '/dental-problem/';

// About Page
export const ABOUT_PAGE = '/about';

// Procedure Doctors
export const FIND_DOCTORS_PAGE = '/dentist';

// Patient Questions
export const PATIENT_QUESTIONS_PAGE = '/my-questions';

// Patient Articles
export const PATIENT_ARTICLES_PAGE = '/list-articles';
export const PROCEDURE_ARTICLE_DETAIL_PAGE = '/view-article';
export const DOCTOR_ARTICLE_DETAIL_PAGE = '/view-article-detail';

//Profile Questions
export const PROFILE_QUESTIONS_PAGE = '/view-questions';
export const PROFILE_QUESTIONS_DETAILS = '/dental-problem';
export const ANSWERED_QUESTIONS_PAGE = '/answered-questions';
export const VIEW_ALL_GALLERY_PAGE = 'gallery';
//User type
export const ADMIN_USERTYPE = '1';
export const DOCTOR_USERTYPE = '2';
export const VISITOR_USERTYPE = '3';

export const VIEW_ALL_QUESTION = '/view-all-question';
export const VIEW_ALL_REVIEW = '/view-all-review';
export const QUESTION_EMAIL_TEMPLATE_PAGE = '/question-mail-template';
export const MY_APPOINTMENT_PAGE = '/appointments';
export const MY_REVIEWS_PAGE = '/my-reviews';
export const BOOKMARK_PAGE = '/bookmarks';

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Menu } from "antd";
import { AvatarWrapper, MenuWrapper } from '../../../components/Navbar/Navbar.style';
import Logo from '../../../components/UI/Logo/Logo';

import { HOME_PAGE } from 'settings/constant';
import { PROCEDURE_LIST_PAGE } from 'settings/constant';
import { SIGNUP_PAGE } from 'settings/constant';
import { LISTING_POSTS_PAGE, FIND_DOCTORS_PAGE } from 'settings/constant';
import ProfileMenu  from './ProfileMenu';

const AuthMenuLogedIn = ({ className }) => {
    let userData = localStorage.getItem('userData');
    const userD = JSON.parse(userData);
    const avatarImg = (userD && userD.avatar !== 'undefined' && userD.avatar !== null && userD.avatar.length > 0)?userD.avatar:'https://edental.s3.us-east-2.amazonaws.com/assets-doctor/images/icon/patient.png';
    return (
        <div>
            <Row gutter={8} style={{alignItems:'center'}}>
                <Col lg={16}>
                    <MenuWrapper className="main_menu">
                        <Menu className={className}>
                            <Menu.Item key="0" className="head-right">
                                <NavLink to={FIND_DOCTORS_PAGE}> Find Dentist </NavLink>
                            </Menu.Item>
                            <Menu.Item key="1" className="head-right">
                                <NavLink to={PROCEDURE_LIST_PAGE}> Procedures </NavLink>
                            </Menu.Item>
                            <Menu.Item key="2" className="head-right">
                                {/*<NavLink to={LISTING_POSTS_PAGE}> Articles </NavLink>*/}
                                <a href={LISTING_POSTS_PAGE}>Articles</a>
                                {/*<NavLink to={LISTING_POSTS_PAGE}> Articles </NavLink>*/}
                            </Menu.Item>
                        </Menu>
                    </MenuWrapper>
                </Col>
                <Col lg={8} className="head-top-right">
                    <AvatarWrapper><ProfileMenu avatar={<Logo src={avatarImg} />} userData={userD}/></AvatarWrapper>
                </Col>
            </Row>
        </div>
    );
};

export default AuthMenuLogedIn;

import React from 'react';
import Logo from 'components/UI/Logo/Logo';
import Footers from 'components/Footer/Footer';
import LogoImage from 'assets/images/white-logo.svg';
import FooterMenu from './FooterMenu';

const Footer = () => {
  return (
    <Footers
      logo={<Logo withLink linkTo="/" className="foo-logo" src={LogoImage} title="eDental." />}
      menu={<FooterMenu />}
      copyright={`Copyright ${new Date().getFullYear()} eDental Portal, All rights reserved`}
    />
  );
};

export default Footer;

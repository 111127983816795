import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { PRIVACY_PAGE } from "settings/constant";
import { webUrl } from "../../../util/ServerUtil";

const FooterMenu = () => {
  return (
    <Menu>
      <Menu.Item key="0">
        <a target="_blank" href={webUrl + "about-us"}>
          About Us
        </a>
      </Menu.Item>
      <Menu.Item key="1">
          <a target="_blank" href={webUrl + "privacy-policy"}>
              Privacy
          </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a target="_blank" href={webUrl + "terms-conditions"}>
          Terms & Conditions
        </a>
      </Menu.Item>
      <Menu.Item key="3">
      <a target="_blank" href={webUrl + "payment-policy"}>
          Payment Policy
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default FooterMenu;

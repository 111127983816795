import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AvatarWrapper, MenuWrapper } from '../../../components/Navbar/Navbar.style';
import { HOME_PAGE } from 'settings/constant';
import { PROCEDURE_LIST_PAGE } from 'settings/constant';
import { SIGNUP_PAGE } from 'settings/constant';
import { LISTING_POSTS_PAGE, FIND_DOCTORS_PAGE } from 'settings/constant';


const AuthMenu = ({ className }) => {
    return (
        <MenuWrapper className="main_menu mobile-out">
            <Menu className={className}>
                <Menu.Item key="0" className="head-right">
                    <NavLink to={FIND_DOCTORS_PAGE}> Find Dentist </NavLink>
                </Menu.Item>
                <Menu.Item key="1" className="head-right">
                    <NavLink to={PROCEDURE_LIST_PAGE}> Procedures </NavLink>
                </Menu.Item>
                <Menu.Item key="2" className="head-right">
                    {/*<NavLink to={LISTING_POSTS_PAGE}> Articles </NavLink>*/}
                    <a href={LISTING_POSTS_PAGE} >Articles</a>
                    {/*<a target={'blank'} href={'https://www.edentalportal.com/exam-guide'} >Guide</a>*/}
                </Menu.Item>
                <Menu.Item key="3" className="logout">
                    <NavLink to={SIGNUP_PAGE}>Login / Signup</NavLink>
                </Menu.Item>
            </Menu>
        </MenuWrapper>
    );
};

export default AuthMenu;

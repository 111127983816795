import React, { useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { AuthContext } from "./context/AuthProvider";
import Layout from "./container/Layout/Layout";
import {
    HOME_PAGE,
    VERIFY_PAGE,
    EMAIL_VERIFY_PAGE,
    VERIFIED_PAGE,
    UPLOAD_PAGE,
    VERIFICATION_PAGE,
    APPROVE_PAGE,
    DOCTOR_PROFILE_PAGE,
    DOCTOR_DASHBOARD_PAGE,
    DOCTOR_PREVIEW,
    PRIVACY_PAGE,
    LISTING_POSTS_PAGE,
    SINGLE_POST_PAGE,
    ADD_HOTEL_PAGE,
    DOCTOR_SETTINGS_PAGE,
    SUCCESS_REGISTER,
    PROCEDURE_PAGE,
    PROCEDURE_DETAIL_PAGE,
    SIGNUPS_PAGE,
    SIGNUP_PAGE,
    SIGNIN_PAGE,
    ABOUT_PAGE,
    ADD_QUESTION_PAGE,
    ALL_QUESTION_PAGE,
    QUESTIONS_DETAILS,
    VIEW_ALL_QUESTION_PAGE,
    FIND_DOCTORS_PAGE,
    PATIENT_QUESTIONS_PAGE,
    PROCEDURE_LIST_PAGE,
    PROCEDURE_ARTICLE_DETAIL_PAGE,
    DOCTOR_ARTICLE_DETAIL_PAGE,
    CHANGE_USER_PASSWORD_PAGE,
    ADD_REVIEW_PAGE,
    VIEW_ALL_GALLERY_PAGE,
    VIEW_ALL_QUESTION,
    VIEW_ALL_REVIEW,
    ADD_DENTIST_REVIEW_PAGE,
    QUESTION_EMAIL_TEMPLATE_PAGE,
    MY_APPOINTMENT_PAGE,
    MY_REVIEWS_PAGE,
    BOOKMARK_PAGE,
} from "./settings/constant";

const Loading = () => null;

const AddListing = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "AddListing" */ "./container/AddListing/AddListing"
        ),
    loading: Loading,
    modules: ["AddListing"],
});

const AgentAccountSettingsPage = Loadable({
    loader: () =>
        import(
            /* webpackChunkName: "AgentAccountSettingsPage" */ "./container/Agent/AccountSettings/AgentAccountSettingsPage"
        ),
    loading: Loading,
    modules: ["AgentAccountSettingsPage"],
});

const Listing = Loadable({
    loader: () =>
        import(
            "./container/Listing/Listing"
        ),
    loading: Loading,
    modules: ["Listing"],
});

const SinglePageView = Loadable({
    loader: () =>
        import(
            "./container/SinglePage/SinglePageView"
        ),
    loading: Loading,
    modules: ["SinglePageView"],
});
/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
    loader: () =>
        import(/* webpackChunkName: "NotFound" */ "./container/404/404"),
    loading: Loading,
    modules: ["NotFound"],
});

//User Login check here
const CustomRoute = ({ component: Component, ...rest }) => {
    const { loggedIn } = useContext(AuthContext);
    if (loggedIn) return <Route component={Component} {...rest} />;
    return <Redirect to={HOME_PAGE} />;
};

const IsVerified = ({ component: Component, props }) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let emailVerified, approvedBy;

    if (userData) {
        emailVerified = userData.email_verified_status;
        approvedBy = userData.approved_by_status;
    }

    if (emailVerified && approvedBy) {
        return <Component {...props} />;
    }

    if (!emailVerified) {
        return <Redirect to={VERIFY_PAGE} />;
    } else if (!approvedBy) {
        return <Redirect to={VERIFICATION_PAGE} />;
    }
};

//User email verification check here
const ProtectedRoute = ({ component, ...rest }) => {
    const { loggedIn } = useContext(AuthContext);
    return (
        <Route
            render={(props) =>
                loggedIn ? (
                    <IsVerified component={component} props={props} />
                ) : (
                    <Redirect to={HOME_PAGE} />
                )
            }
            {...rest}
        />
    );
};

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
    const { loggedIn } = useContext(AuthContext);
    return (
        <Layout>
            <Switch>
                <ProtectedRoute
                    exact
                    path={ADD_HOTEL_PAGE}
                    component={AddListing}
                />

                <ProtectedRoute
                    exact
                    path={DOCTOR_SETTINGS_PAGE}
                    component={AgentAccountSettingsPage}
                />

                <Route
                    exact
                    path={LISTING_POSTS_PAGE}
                    component={Listing}
                />

                <Route
                    exact
                    path={`${SINGLE_POST_PAGE}/:id`}
                    component={SinglePageView}
                />

                {/*SINGLE_POST_PAGE*/}

                <CustomRoute
                    exact
                    path={VERIFY_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Verify/Verify"),
                        loading: Loading,
                        modules: ["Verify"],
                    })}
                />
                {/* <CustomRoute
          path={EMAIL_VERIFY_PAGE}
          component={Loadable({
            loader: () => import("./container/Verify/Verify"),
            loading: Loading,
            modules: ["Verify"],
          })}
        /> */}
                <Route
                    exact
                    path={SUCCESS_REGISTER}
                    component={Loadable({
                        loader: () => import("./container/successRegistered"),
                        loading: Loading,
                        modules: ["success-registered"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={VERIFIED_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Verified/Verified"),
                        loading: Loading,
                        modules: ["Verified"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={UPLOAD_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Upload/Upload"),
                        loading: Loading,
                        modules: ["Uploading"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={VERIFICATION_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Verification/Verification"),
                        loading: Loading,
                        modules: ["Verification"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={APPROVE_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Approved/Approved"),
                        loading: Loading,
                        modules: ["Approved"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={DOCTOR_PROFILE_PAGE}
                    component={Loadable({
                        loader: () =>
                            import(
                                "./container/Agent/AccountSettings/AgentAccountSettingsPage"
                            ),
                        loading: Loading,
                        modules: ["AgentAccountSettingsPage"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={DOCTOR_DASHBOARD_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Dashboard/Dashboard"),
                        loading: Loading,
                        modules: ["Dashboard"],
                    })}
                />

                <ProtectedRoute
                    exact
                    path={PATIENT_QUESTIONS_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Questions/Questions"),
                        loading: Loading,
                        modules: ["Questions"],
                    })}
                />
                <ProtectedRoute
                    exact
                    path={MY_APPOINTMENT_PAGE}
                    component={Loadable({
                        loader: () => import("./container/MyAppointments/MyAppointments"),
                        loading: Loading,
                        modules: ["MyAppointments"],
                    })}
                />
                <ProtectedRoute
                    exact
                    path={BOOKMARK_PAGE}
                    component={Loadable({
                        loader: () => import("./container/BookMarks/BookMark"),
                        loading: Loading,
                        modules: ["BookMark"],
                    })}
                />
                <ProtectedRoute
                    exact
                    path={MY_REVIEWS_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Agent/MyReviews/MyReviews"),
                        loading: Loading,
                        modules: ["MyReviews"],
                    })}
                />
                <Route
                    exact
                    path={'*' + VIEW_ALL_QUESTION}
                    component={Loadable({
                        loader: () => import("./components/Questions/QuestionViewAllComponent"),
                        loading: Loading,
                        modules: ["Questions"],
                    })}
                />

                <Route
                    exact
                    path={'*' + VIEW_ALL_REVIEW}
                    component={Loadable({
                        loader: () => import("./components/Reviews/ViewAllReviewsComponent"),
                        loading: Loading,
                        modules: ["Questions"],
                    })}
                />

                <Route
                    exact
                    path={DOCTOR_PREVIEW + ':id'}
                    component={Loadable({
                        loader: () =>
                            import("./container/Agent/AccountDetails/AgentDetailsViewPage"),
                        loading: Loading,
                        modules: ["AgentDetailsViewPage"],
                    })}
                />
                <Route
                    exact
                    path={PROCEDURE_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Procedures/Procedures"),
                        loading: Loading,
                        modules: ["Procedures"],
                    })}
                />
                <Route
                    exact
                    path={PROCEDURE_LIST_PAGE}
                    component={Loadable({
                        loader: () => import("./container/ProceduresList/ProceduresList"),
                        loading: Loading,
                        modules: ["ProceduresList"],
                    })}
                />
                <Route
                    exact
                    path={PROCEDURE_DETAIL_PAGE + '/:id'}
                    component={Loadable({
                        loader: () => import("./container/ProcedureDetails/ProcedureDetails"),
                        loading: Loading,
                        modules: ["ProcedureDetails"],
                    })}
                />
                <Route
                    exact
                    path={FIND_DOCTORS_PAGE}
                    component={Loadable({
                        loader: () => import("./container/ProcedureDetails/FindDoctors/FindDoctors"),
                        loading: Loading,
                        modules: ["FindDoctors"],
                    })}
                />
                <Route
                    exact
                    path={'*/' + VIEW_ALL_GALLERY_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Agent/AccountDetails/ViewAllGallery"),
                        loading: Loading,
                        modules: ["ViewAllGallery"],
                    })}
                />
                <Route
                    exact
                    path={PROCEDURE_ARTICLE_DETAIL_PAGE}
                    component={Loadable({
                        loader: () => import("./container/ProcedureDetails/ProcedureArticleDetail/ProcedureArticleDetail"),
                        loading: Loading,
                        modules: ["ViewArticle"],
                    })}
                />
                <Route
                    exact
                    path={DOCTOR_ARTICLE_DETAIL_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Agent/AccountDetails/DoctorArticleDetail"),
                        loading: Loading,
                        modules: ["ViewDoctorArticle"],
                    })}
                />
                <Route
                    exact
                    path={SIGNUP_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Signup/Signup"),
                        loading: Loading,
                        modules: ["Signup"],
                    })}
                />
                <Route
                    exact
                    path={SIGNIN_PAGE}
                    component={Loadable({
                        loader: () => import("./container/SignIn/SignIn"),
                        loading: Loading,
                        modules: ["Signin"],
                    })}
                />
                <Route
                    exact
                    path={ABOUT_PAGE}
                    component={Loadable({
                        loader: () => import("./container/About/About"),
                        loading: Loading,
                        modules: ["About"],
                    })}
                />
                <Route
                    exact
                    path={ADD_QUESTION_PAGE}
                    component={Loadable({
                        loader: () => import("./container/ProcedureDetails/AddNewQuestion/AddNewQuestion"),
                        loading: Loading,
                        modules: ["AddNewQuestion"],
                    })}
                />
                <Route
                    exact
                    path={ALL_QUESTION_PAGE}
                    component={Loadable({
                        loader: () => import("./container/ProcedureDetails/QuestReplies/QuestReplies"),
                        loading: Loading,
                        modules: ["QuestReplies"],
                    })}
                />
                <Route
                    exact
                    path={QUESTIONS_DETAILS + ':id'}
                    component={Loadable({
                        loader: () => import("./container/ProcedureDetails/QuestionsReplies/QuestionDetails"),
                        loading: Loading,
                        modules: ["QuestionsReplies"],
                    })}
                />
                <Route
                    exact
                    path={VIEW_ALL_QUESTION_PAGE}
                    component={Loadable({
                        loader: () => import("./container/ProcedureDetails/ViewAllQuestions/ViewAllQuestions"),
                        loading: Loading,
                        modules: ["ViewAllQuestions"],
                    })}
                />
                <Route
                    exact
                    path={QUESTION_EMAIL_TEMPLATE_PAGE}
                    component={Loadable({
                        loader: () => import("./container/MailTemplate/QuestionMailTemplate"),
                        loading: Loading,
                        modules: ["QuestionMailTemplate"],
                    })}
                />
                <Route
                    exact
                    path={CHANGE_USER_PASSWORD_PAGE}
                    component={Loadable({
                        loader: () => import("./container/ChangePassword/ChangePassword"),
                        loading: Loading,
                        modules: ["ChangePassword"],
                    })}
                />
                <Route
                    exact
                    path={PRIVACY_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Privacy/Privacy"),
                        loading: Loading,
                        modules: ["Privacy"],
                    })}
                />
                <Route
                    exact
                    path={HOME_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Home/Home"),
                        loading: Loading,
                        modules: ["Home"],
                    })}
                />
                <Route
                    exact
                    path={ADD_REVIEW_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Review/AddReview/NewReview"),
                        loading: Loading,
                        modules: ["Review"],
                    })}
                />
                <Route
                    exact
                    path={ADD_DENTIST_REVIEW_PAGE}
                    component={Loadable({
                        loader: () => import("./container/Review/AddReview/DoctorReview"),
                        loading: Loading,
                        modules: ["Review"],
                    })}
                />

                {/*<Route
          path={`${SINGLE_POST_PAGE}/:slug`}
          component={Loadable({
            loader: () => import("./container/SinglePage/SinglePageView"),
            loading: Loading,
            modules: ["SinglePageView"],
          })}
        />*/}

                {/*<Route
          path={LISTING_POSTS_PAGE}
          component={Loadable({
            loader: () => import("./container/Listing/Listing"),
            loading: Loading,
            modules: ["Listing"],
          })}
        />*/}

                <Route component={NotFound} />
            </Switch>
        </Layout>
    );
};

export default Routes;

import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';
import {
    HOME_PAGE,
    HOME_PAGE_STUDENTS,
    HOME_PAGE_PATIENTS,
    DOCTOR_SETTINGS_PAGE,
    LISTING_POSTS_PAGE,
    FIND_DOCTORS_PAGE,
    PROCEDURE_LIST_PAGE,
} from 'settings/constant';

const MobileMenu = ({ className }) => {
  // auth context
  const { loggedIn, logOut } = useContext(AuthContext);

  return (
    <Menu className={className}>
      {/*<Menu.Item key="0">*/}
        {/*<a href="https://dev-doctors.edentalportal.com/">For Doctors</a>*/}
        {/*/!*<NavLink to={HOME_PAGE_PATIENTS}>*!/*/}
            {/*/!*For Patients*!/*/}
        {/*/!*</NavLink>*!/*/}
      {/*</Menu.Item>*/}
      <Menu.Item key="1">
        <a href="http://www.edentalportal.com/">Licensing Exams</a>
        {/*<NavLink to={HOME_PAGE_STUDENTS}>*/}
            {/*For Students*/}
        {/*</NavLink>*/}
      </Menu.Item>
        {loggedIn && (
            <Menu.Item key="2">
                <NavLink to={FIND_DOCTORS_PAGE}> Find Dentist </NavLink>
            </Menu.Item>
        )}
        {loggedIn && (
            <Menu.Item key="3">
                <NavLink to={PROCEDURE_LIST_PAGE}> Procedures </NavLink>
            </Menu.Item>
        )}
        {loggedIn && (
            <Menu.Item key="4">
                <a href={LISTING_POSTS_PAGE}>Articles</a>
            </Menu.Item>
        )}
      {loggedIn && (
        <Menu.Item key="5">
          <NavLink to={DOCTOR_SETTINGS_PAGE}>Account Settings</NavLink>
        </Menu.Item>
      )}
      {loggedIn && (
        <Menu.Item key="6">
          <button onClick={logOut}>Log Out</button>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MobileMenu;

import React, { useState, useEffect, useMemo } from "react";
import { Button, notification, Space } from "antd";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { VISITOR_USERTYPE } from 'settings/constant';
import { DOCTOR_USERTYPE } from 'settings/constant';
import { apiUrl } from "../util/ServerUtil";
export const AuthContext = React.createContext();

const loginUrl = apiUrl + "Auth/login";
const registrationUrl = apiUrl +"Auth/registration";
const resendUrl = apiUrl +"Auth/resendVerificationMail";
const checkProfileUrl = apiUrl + "admin/users/userdetails";
// const logoutUrl = "https://cors-anywhere.herokuapp.com/https://dev-api.edentalportal.com/api/Auth/logout";

let userData = localStorage.getItem("userData");
let userToken = localStorage.getItem("token");

const AuthProvider = (props) => {
    let history = useHistory();
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [showFeedBack, setShowFeedBack] = useState(false);

    const checkLoggedIn = () => {
        setUser(userData ? JSON.parse(userData) : {});
        setLoggedIn(userToken ? true : false);
    };


    const checkUserProfile = () => {
        let user_id = localStorage.getItem("user_id");
        if (user_id) {
            let params = {
                id: user_id,
                user_type_id: VISITOR_USERTYPE,
            };
            return new Promise((resolve, reject) => {
                fetch(checkProfileUrl, {
                    method: "POST",
                    body: JSON.stringify(params),
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        resolve(responseJson);
                        if(responseJson.status === "Success"){
                            localStorage.setItem("user_id", responseJson.data.id);
                            // localStorage.setItem("token", responseJson.token);
                            localStorage.setItem(
                                "userData",
                                JSON.stringify(responseJson.data)
                            );
                            setLoggedIn(true);
                            setUser(responseJson.data);
                        }
                        // if (responseJson.status === "Success") {
                        //   if (responseJson.data.email_verified == false) {
                        //     localStorage.setItem("user_id", responseJson.data.id);
                        //     localStorage.setItem("token", responseJson.token);
                        //     localStorage.setItem(
                        //       "userData",
                        //       JSON.stringify(responseJson.data)
                        //     );
                        //     setLoggedIn(true);
                        //     setUser(responseJson.data);
                        //     history.push("/verify");
                        //   } else if (
                        //     responseJson.data.licence_document == false &&
                        //     responseJson.data.email_verified == true
                        //   ) {
                        //     localStorage.setItem("user_id", responseJson.data.id);
                        //     localStorage.setItem("token", responseJson.token);
                        //     localStorage.setItem(
                        //       "userData",
                        //       JSON.stringify(responseJson.data)
                        //     );
                        //     setLoggedIn(true);
                        //     setUser(responseJson.data);
                        //     history.push("/upload");
                        //   } else {
                        //     localStorage.setItem("user_id", responseJson.data.id);
                        //     localStorage.setItem("token", responseJson.token);
                        //     localStorage.setItem(
                        //       "userData",
                        //       JSON.stringify(responseJson.data)
                        //     );
                        //     setLoggedIn(true);
                        //     setUser(responseJson.data);
                        //     history.push("/dashboard");
                        //   }
                        // } else {
                        //   notification["error"]({
                        //     message: "Invalid Login",
                        //     description: responseJson.message,
                        //     placement: "bottomRight",
                        //   });
                        // }
                        //localstorage code set here
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
        else {
            setUser({});
            setLoggedIn(false);
            localStorage.clear();
        }
    };


    useMemo(() => {
        checkUserProfile();
        if (userToken && userData) {
            setUser(JSON.parse(userData));
            setLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (userToken && userData) {
            setUser(JSON.parse(userData));
            setLoggedIn(true);
        }
    }, [userData, userToken]);

    const signIn = (params) => {
        let questionData = localStorage.getItem("questionData");
        return new Promise((resolve, reject) => {
            fetch(loginUrl, {
                method: "POST",
                body: JSON.stringify(params),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson);
                    if (responseJson.status === "Success") {
                        localStorage.setItem("user_id", responseJson.data.id);
                        localStorage.setItem("token", responseJson.token);
                        localStorage.setItem("userData", JSON.stringify(responseJson.data));
                        setLoggedIn(true);
                        setUser(responseJson.data);
                        if (responseJson.data.email_verified_status == false) {
                            localStorage.setItem("user_id", responseJson.data.id);
                            localStorage.setItem("token", responseJson.token);
                            localStorage.setItem(
                                "userData",
                                JSON.stringify(responseJson.data)
                            );
                            setLoggedIn(true);
                            setUser(responseJson.data);
                            history.push("/verify");
                        } else if (
                            (responseJson.data.user_type_id == DOCTOR_USERTYPE &&
                                responseJson.data.licence_document_status == false) ||
                            responseJson.data.approved_by_status == false
                        ) {
                            localStorage.setItem("user_id", responseJson.data.id);
                            localStorage.setItem("token", responseJson.token);
                            localStorage.setItem(
                                "userData",
                                JSON.stringify(responseJson.data)
                            );
                            setLoggedIn(true);
                            setUser(responseJson.data);
                            history.push("/verify");
                            window.location.reload();
                        } else if (responseJson.data.contact_number === '') {
                            localStorage.setItem("user_id", responseJson.data.id);
                            localStorage.setItem("token", responseJson.token);
                            localStorage.setItem(
                                "userData",
                                JSON.stringify(responseJson.data)
                            );
                            setLoggedIn(true);
                            setUser(responseJson.data);
                            history.push("/dashboard");
                            window.location.reload();
                        } else if (questionData != null) {
                            localStorage.setItem("user_id", responseJson.data.id);
                            localStorage.setItem("token", responseJson.token);
                            localStorage.setItem(
                                "userData",
                                JSON.stringify(responseJson.data)
                            );
                            setLoggedIn(true);
                            setUser(responseJson.data);
                            history.push("/ask-question");
                        }else {
                            localStorage.setItem("user_id", responseJson.data.id);
                            localStorage.setItem("token", responseJson.token);
                            localStorage.setItem(
                                "userData",
                                JSON.stringify(responseJson.data)
                            );
                            setLoggedIn(true);
                            setUser(responseJson.data);
                            history.push("/dashboard");
                            window.location.reload();
                        }
                    } else {
                        notification["error"]({
                            message: "Invalid Login",
                            description: responseJson.message,
                            placement: "bottomRight",
                        });
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const signUp = (params) => {
        let questionData = localStorage.getItem("questionData");
        if(params){
            params.role_id = "4";
            params.user_type_id = VISITOR_USERTYPE;
            params.t_n_c = "1";
            params.source = params.source === 'google'? 'GOOGLE' : params.source === 'facebook'? 'FACEBOOK': 'EMAIL';
            params.provider_id = params.provider_id;
        }

        return new Promise((resolve, reject) => {
            fetch(registrationUrl, {
                method: "POST",
                body: JSON.stringify(params),
            })
                .then((response) => response.json())
                // console.log("res",)
                .then((response) => {
                    resolve(response);
                    if (response.status == "Success") {
                        if (questionData !== null) {
                            localStorage.setItem("user_id", response?.data?.id);
                            localStorage.setItem("token", response?.token);
                            localStorage.setItem(
                              "userData",
                              JSON.stringify(response.data)
                            );
                            setLoggedIn(true);
                            setUser(response.data);
                            history.push("/ask-question");
                        } else {
                            localStorage.setItem("user_id", response.data.id);
                            localStorage.setItem("token", response.token);
                            localStorage.setItem("userData", JSON.stringify(response.data));
                            setLoggedIn(true);
                            setUser(response.data);
                            history.push("/verify");
                            window.location.reload();
                        }
                    } else {
                        notification["error"]({
                            message: "Invalid Registration",
                            description: response.message,
                            placement: "bottomRight",
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    };

    const logOut = () => {
        setUser({});
        setLoggedIn(false);
        localStorage.clear();
        // setShowFeedBack(true); // to showing feedback popup after logout
        /*notification["success"]({
            message: "Logout",
            description: "Logout successfully",
            placement: "bottomRight",
        });*/
    };

    const handlefeedbackcloseLogout = () => {
      setShowFeedBack(false);
    };

    // const logOut = () => {
    //     return new Promise((resolve, reject) => {
    //         fetch(logoutUrl, {
    //             method: "GET",
    //             headers: {
    //                 Authorization: "Bearer " + localStorage.getItem("token"),
    //             },
    //         })
    //             .then((response) => response.json())
    //             .then((responseJson) => {
    //                 resolve(responseJson);
    //                 if (responseJson.status === "Success") {
    //                     notification["success"]({
    //                         message: "Logout",
    //                         description: responseJson.message,
    //                         placement: "bottomRight",
    //                     });
    //                     setUser({});
    //                     setLoggedIn(false);
    //                     localStorage.clear();
    //                 } else {
    //                     notification["error"]({
    //                         message: "Invalid",
    //                         description: responseJson.message,
    //                         placement: "bottomRight",
    //                     });
    //                 }
    //             })
    //             .catch((error) => {
    //                 reject(error);
    //             });
    //     });
    // };

    const reSendVerification = (params) => {
        params.user_id = localStorage.getItem("user_id");
        return new Promise((resolve, reject) => {
            fetch(resendUrl, {
                method: "POST",
                body: JSON.stringify(params),
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson);
                    if (responseJson.status === "Success") {
                        notification["success"]({
                            message: responseJson.message,
                            description: responseJson.message,
                            placement: "bottomRight",
                        });
                    } else {
                        notification["error"]({
                            message: responseJson.message,
                            description: responseJson.message,
                            placement: "bottomRight",
                        });
                    }
                    //localstorage code set here
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
    return (
        <AuthContext.Provider
            value={{
                loggedIn,
                logOut,
                signIn,
                signUp,
                checkLoggedIn,
                reSendVerification,
                checkUserProfile,
                user,
                showFeedBack,
                handlefeedbackcloseLogout,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

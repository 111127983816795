import { all, call, fork, put, takeEvery, takeLatest, delay } from 'redux-saga/effects';





export default function* rootSaga() {
    yield all([
        //   fork(watchLoginWithPassword),

    ]);
}
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const HeaderWrapper = styled.header`
  width: 100%;
  .sticky-outer-wrapper {
    nav {
        padding: 0 120px;
        @media only screen and (max-width: 991px) {
            padding: 0 20px;
        }        
        .head-top-right > div {
            margin-left: 0;
        }
        &.is_default {
            .ant-menu:not(.active) {
                li {
                    font-size: 14px;    
                    a {
                        color: #707070;
                        &.active {
                            color: #0077cc;
                        }
                    }
                }
            }
            .hWrhgK {
                margin-left: 0;
            }
            .auth_menu {
                .ant-menu {
                  li {
                    &:last-child {
                      margin-left: 10px;
                      a {
                        color: #324755 !important;
                        padding-left: 20px;
                        padding-right: 20px;
                        border-radius: 26px;
                        font-size: 16px;
                        font-weight: bold;
                        border: 1px solid #707070;
                        background-color: #fff;
                        &:hover {
                            background-color: #0083ff;
                            color: #fff !important;
                            border: 1px solid #0083ff;
                        }
                      }
                    }
                  }
                }
              }
        }
    }    
  }
  .sticky-outer-wrapper {
    nav {
    > div{
        max-width: 100%;
        width: 100%;
        justify-content: space-between;
    }      
      &.is_transparent {
        > div > div {
          a {
            img {
              opacity: 1;
            }
          }
        }
      
        .ant-menu:not(.active) {
          li {
            font-size: 14px;    
            a {
                color: #707070;
              &.active {
                color: ${themeGet('primary.0', '#0077cc')};
                border-bottom: 3px solid ${themeGet('primary.0', '#0077cc')};
              }
              &:hover {
                color: ${themeGet('primary.0', '#0077cc')};
              }
            }
           
          }
        }
        .hWrhgK {
            margin-left: 0;
        }
        .auth_menu {
            .ant-menu {
              li {
                &:last-child {
                  margin-left: 10px;
                  a {
                    color: #324755 !important;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 26px;
                    font-size: 16px;
                    font-weight: bold;
                    border: 1px solid #707070;
                    background-color: #fff;
                    &:hover {
                        background-color: #0077cc;
                        color: #fff !important;
                        border: 1px solid #0077cc;
                    }
                  }
                }
              }
              
              .head-right a {
                border: 0 !important;
              }
            }
          }
        
      }

      &.transparent {
        > div > div {
          a {
            img {
              opacity: 1;
            }
          }
        }
      }
      .auth_menu {
            .ant-menu {
              li {
                &:last-child {
                  a {
                    color: ${themeGet('color.1', '#FFFFFF')};
                    padding-left:20px;
                    padding-right:20px;
                    border-radius: 20px;
                    padding-top: 12px;
                    padding-bottom: 12px;
                  }
                }
              }
            }
          }
      
    }

    &.isHeaderSticky {
      nav {
      
        &.is_transparent {
          color: ${themeGet('text.0', '#2C2C2C')};
          background-color: ${themeGet('color.1', '#ffffff')};
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

          > div > div {
            a {
              img {
                opacity: 1;
              }
            }
          }

          .ant-menu {
            li {
              a {
                color: ${themeGet('text.0', '#2C2C2C')};
                &:hover {
                  color: ${themeGet('primary.0', '#0077cc')};
                }
                &.active {
                  color: ${themeGet('primary.0', '#0077cc')};
                  border-bottom: 3px solid ${themeGet('primary.0', '#0077cc')};
                }
              }
            }
          }

          .auth_menu {
            .ant-menu {
              li {
                &:last-child {
                  a {
                    color: ${themeGet('color.1', '#2C2C2C')};
                  }
                }
              }
            }
          }
        }

        &.transparent {
          background-color: ${themeGet('color.1', '#ffffff')};
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

          > div > div {
            a {
              img {
                opacity: 1;
              }
            }
          }

          .hamburg-btn {
            > span {
              background-color: ${themeGet('text.0', '#2C2C2C')};
            }
          }
        }
        
      }
    }
    .avatar-dropdown .dropdown-handler {
        border: 1px solid #324755;
        h3 {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 16px;
            color: #324755;
            text-transform: capitalize;
        }    
    }
  }
`;

export const MobileNavbar = styled.nav`
  display: flex;
  padding: 0 25px;
  align-items: center;
  justify-content: space-between;
  min-height: 82px;
  width: 100%;

  &.default {
    border-bottom: 1px solid ${themeGet('border.3', '#E6E6E6')};
    background-color: ${themeGet('color.1', '#ffffff')};
  }

  .hamburg-btn {
    border: 0;
    padding: 0;
    width: auto;
    height: auto;
    background-color: transparent;

    > span {
      display: block;
      width: 20px;
      height: 2px;
      margin: 4px 0;
      border-radius: 5px;
      background-color: ${themeGet('text.0', '#2C2C2C')};
      transition: all 0.3s ease;
    }

    &:hover,
    &.active {
      background-color: transparent;

      > span {
        width: 23px;
        background-color: ${themeGet('primary.0', '#0077cc')};
      }
    }

    &::after {
      display: none;
    }
  }

  &.transparent {
    position: fixed;
    z-index: 9999;

    .hamburg-btn {
      > span {
        background-color: ${themeGet('color.1', '#ffffff')};
      }
    }
  }
`;

export const LogoArea = styled.div`
  display: flex;
  align-items: center;

  > a {
    flex-shrink: 0;
    margin-right: 27px;

    @media only screen and (max-width: 480px) {
      margin-right: 20px;
    }

    img {
      height: 28px;
      max-width: inherit;
    }
  }
`;

export const CloseDrawer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 15px 12px;

  > button {
    border: 0;
    padding: 0;
    background-color: transparent;
    font-size: 38px;
    line-height: 1;
    height: auto;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: ${themeGet('text.1', '#909090')};
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      outline: 0;
      color: ${themeGet('text.0', '#2C2C2C')};
    }
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  padding: 25px 35px;
  align-items: center;
  background-color: ${themeGet('color.2', '#F7F7F7')};
`;

export const AvatarImage = styled.div`
  flex-shrink: 0;
  margin-right: 15px;

  img {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const AvatarInfo = styled.div`
  h3 {
    color: ${themeGet('text.0', '#2C2C2C')};
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  a {
    color: ${themeGet('text.0', '#2C2C2C')};
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;

    &:hover,
    &:focus {
      text-decoration: none;
      outline: 0;
      color: ${themeGet('primary.0', '#0077cc')};
    }
  }
`;

export const NavbarSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 430px;
  width: 100%;
  margin-left: 30px;

  @media only screen and (max-width: 1200px) {
    max-width: 320px;
  }

  @media only screen and (max-width: 480px) {
    padding-right: 15px;
    box-sizing: border-box;
  }

  .map_autocomplete {
    width: 100%;
  }

  input {
    font-size: 17px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 30px;
    border-radius: 4px;
    color: ${themeGet('text.0', '#2C2C2C')};
    border: 1px solid ${themeGet('border.0', '#EBEBEB')};
    background-color: ${themeGet('color.2', '#F7F7F7')};
    height: 50px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.3s ease;
    @media only screen and (max-width: 1200px) {
      height: 48px;
    }

    &::placeholder {
      color: rgb(72, 72, 72);
    }

    &:hover,
    &:focus {
      border-color: ${themeGet('primary.0', '#0077cc')};
    }
  }

  > svg {
    position: absolute;
    z-index: 1;
    top: auto;
    right: 15px;
    width: 20px;
    height: 20px;

    @media only screen and (max-width: 480px) {
      right: 25px;
    }
  }
`;

export default HeaderWrapper;
